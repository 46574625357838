import React from 'react'
import Layout from '../../components/layout';
import Helmet from "react-helmet"
import SearchComponent from "../../components/search/searchrewards"
import Seo from "../../components/seo"

const Home = () => {
    return (
        <Layout includeSearchBar={false}>
            <Seo title="Employee Recognition Fundamentals" />
            <Helmet>
                <title>Vantage Rewards </title>

                <meta name="description" content="Join our team as we redefine the recognition and rewards infrastructure for thousands of businesses globally. Check out available openings." />
                <meta property="twitter:title" content="Vantage Circle" />
                        
                <meta property="twitter:description" content="Join our team as we redefine the recognition and rewards infrastructure for thousands of businesses globally. Check out available openings." />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://docs.vantagecircle.com/vantage-recognition/employeerecognition-fundamentals/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <meta property="og:title" content="Vantage Circle" />

                <meta property="og:description" content="Join our team as we redefine the rewards and recognition infrastructure for thousands of businesses globally. Check out available openings." />
                <link rel="canonical" href="https://docs.vantagecircle.com/vantage-recognition/employeerecognition-fundamentals/"></link>
            </Helmet>
            <SearchComponent />
            <section className="container max-w-6xl mx-auto py-10 md:pt-20 md:pb-0">
                <div className="mx-auto px-6 mb-10">
                    <div className="text-center">
                        <h2 className="section-title">Employee Recognition & Rewards<span className="text-orange">Fundamentals</span></h2>
                    </div>
                </div>
            </section>

            <section className="container max-w-4xl vc-doc-content px-6 md:px-12 pb-10">
                <h2>What does recognition look like?</h2>
                <p>
                    At its core, <a href="https://www.vantagecircle.com/en/blog/employee-recognition/">employee recognition</a> is the act of acknowledging and appreciating employees for their contributions to the company.</p>
                <p>It could be a pat on the back for a job well done, a social media shout-out for a great new idea, or even a bonus for meeting a monthly goal.</p>
                <p>Here are some benefits of impactful <a href="https://www.vantagecircle.com/en/blog/employee-rewards-and-recognition/">rewards and recognition</a>:</p>
                <ul className="list-inside orangebullets">
                    <li>Improves <a href="https://www.vantagecircle.com/en/blog/employee-motivation/">employee motivation</a></li>
                    <li>Impacts <a href="https://www.vantagecircle.com/en/blog/employee-retention-strategies/">retention</a></li>
                    <li>Boosts <a href="https://www.vantagecircle.com/en/blog/employee-engagement/">employee engagement</a></li>
                    <li>Reinforces <a href="https://www.vantagecircle.com/en/blog/company-core-values-positive-impact/">company values</a></li>
                </ul>
                <h2>Why are companies going digital with R&R?</h2>
                <p>Employees today seek frequent, agile, and instant recognition.</p>
                <p>It may be impossible for leaders to go up to each employee and thank them for their day-to-today efforts. And with more people working remotely, companies are looking for ways to engage employees.</p>
                <p>Here is where the role of <a href="https://www.vantagecircle.com/en/blog/employee-recognition-software/">digital R&R tools</a> comes into play.</p>
                <p>In 2021, we conducted a <a href="https://www.vantagecircle.com/hr-academy/industry-reports/industry-report-employee-rewards-and-recognition-trends/">study on R&R</a> trends for remote working. Based on 248 responses from industry experts, we got the following key findings:</p>
                <ul className="list-inside orangebullets">
                    <li>58% of HRs said adopting digital R&R platforms impacts overall engagement and retention.</li>
                    <li>79% of respondents want an agile R&R platform for remote employees.</li>
                    <li>55% of respondents want mobile-friendly R&R platforms for remote workers.</li>
                    <li>53% of respondents said digital R&R platforms enhance HR efficiency while working remotely.</li>
                </ul>
                <p>Thus, a digital solution to R&R is no longer a luxury—it has become a strategic imperative.</p>
                <p>It makes the whole process smoother, faster, regular, and more frequent.</p>
                <h2>Forms of rewards and recognition</h2>
                <p>Rewards and recognition can take many forms. Let’s have a look at some of them.</p>
                <h3>Monetary rewards</h3>
                <p>Monetary recognition means that the employees are given a financial reward when they show desired behavior at work. They could be a cash award, <a href="https://www.vantagecircle.com/en/blog/gift-cards-employee-reward/">gift cards</a>, or even discretionary company shares.</p>
                <p>The monetary awards in Vantage Rewards are based on the points-based reward system.</p>
                <p>These points are tied up to a specific monetary value. Employees can redeem these points for merchandise and gift vouchers.</p>
                <p>There are two types of monetary awards in Vantage Rewards:<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Normal awards<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Panel awards
                </p>
                <h4><strong>Normal Award</strong></h4>
                <p>Normal awards are monetary awards that don’t require any voting. A manager nominates an employee for the award.</p> <p>They decide on the monetary value and cite a reason for the reward. Finally, the reward is conferred to the employee after it has been approved by an approver.</p>
                <h4><strong>Panel Awards</strong></h4>
                <p>Panel Awards are monetary awards that are based on a voting system. It is a multi-level approver award system that provides employees with a fair and equal chance to win.</p>
                <p>A panel award is created for a certain period of time. Within that period, managers nominate employees for the award. A panel of approvers vote on the nominated employees and a winner selector then selects the winner(s) based on the votes.</p>
                <h3>Non-Monetary rewards</h3>
                <p>Non-monetary awards are intangible forms of acknowledgment whose worth cannot be measured definitively. It can be a meaningful ‘well-done’, a high-five, or a special shout-out on social media. These gestures are excellent sources of motivation and play a critical role in employee retention.</p>
                <p>In Vantage Rewards, non-monetary awards are given to employees in the form of badges. Badges are customizable and can be used for spot recognition.</p>
                <h3>Service Milestones</h3>
                <p><a href="https://www.vantagecircle.com/en/blog/years-of-service-award/">Service milestone awards</a> celebrate the tenure of employees who have worked with the company for a considerable amount of time. It facilitates peers to give out anniversary wishes and congratulatory posts.</p>
                <p>There are two types of service awards in Vantage Rewards:</p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Long Service Awards (LSA)<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Service Yearbook
                </p>
                <h4><strong>Long Service Awards (LSA)</strong></h4>
                <p>These are used to recognize employee tenure and are usually accompanied with e-certificates or monetary awards. </p>
                <h4><strong>Service Yearbook</strong></h4>
                <p>It is a personalized online yearbook that allows peers to share congratulatory messages for an employee’s service anniversary.</p>
            </section>
        </Layout>
    )
}

export default Home